/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

 @import url(https://fonts.googleapis.com/css?family=Montserrat);

%font-sans {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}


%font-alt {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}


body {
  background: #fff;
  color: $text-color;
  @extend %font-sans;
  @include font-values(16,16);
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  hyphens: none;
}

h1 {
  @extend %font-sans;
  @include fluid-type(3rem, 5rem);
  font-weight: 600;
}

h2 {
  @extend %font-sans;
  @include fluid-type(2rem, 3.3rem);
  font-weight: 600;
}

h3 {
  @extend %font-sans;
  @include fluid-type(2rem, 2.8rem);
  line-height: 1.2;
  font-weight: 700;

  &.bar{
    position: relative;
    margin-left: 20px;

  &:before{
        margin-left: -20px;
    content: "";
    width: 3px;
    position: absolute;
    background-color: #000;
    height: 100%;
    display: block;
  }
  &__green:before{
        background-color: #96C93E;
  }
}
}

h4 {
  @extend %font-sans;
  @include fluid-type(1rem, 1.2rem);
  font-weight: 400;
}

h5 {
  @extend %font-sans;
  @include fluid-type(1rem, 1rem);
  font-weight: 400;
  color: #BABEC8;
}

h6{
  @extend %font-sans;
  @include fluid-type(0.8rem, 0.8rem);
  font-weight: 400;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
    @extend %font-sans;
  @include fluid-type(1rem, 1rem);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.7rem;

  b, strong{
    color: #222427;
  }
}
