// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.gallery-top {
   height: 80%!important;
   width: 100%;
   padding-left: 5px !important;
 }
 .gallery-thumbs {
   height: 20%!important;
   box-sizing: border-box;
   padding: 10px 0;
 }
 .gallery-thumbs .swiper-slide {
   width: 25%;
   height: 100%;
   opacity: 0.4;
 }
 .gallery-thumbs .swiper-slide-active {
   opacity: 1;
 }

.btn {
  @extend %font-sans;
  @include font-values(15,15);
  letter-spacing: 0.04em;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  border: solid 2px;
  padding: 0 20px;
  border-radius: 3px;
  line-height: 45px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &__white {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #000 ;
    text-transform: uppercase;
    }
  &__primary {
    background-color: $brand-color !important;
    border-color: $brand-color !important;
    color: #fff;
      text-transform: uppercase;

    &:hover{
      background-color: transparent !important;
      color: $brand-color !important;
    }
  }

}

.dropdown:hover .dropdown-menu {
  display: block;
}

.tabs {
  @extend %font-sans;
  @include font-values(18,18);
}
