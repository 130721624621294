/// Nav layout
nav {
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-size: 1rem;
  ul {
    margin: 0 0 0 0;
    padding: 0;
  }
  .nav_link {
    font-size: 0.7em;
    font-weight: 500;

    a{
      color:#fff;
    }
    }

    @media screen and (max-width: 640px) {
      .nav_link {
        display: none;
        &:first-child{
          display: block;
          z-index: 10;
        }
      }
      }

  }
