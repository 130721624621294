








































.swiper {
    height: 400px;

    .swiper-slide {
        background: #444;
    }
}
