// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  @include box-sizing(border-box);
  font-size: 14px;
}

.bg-brand{
  background-color: $brand-full;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: border-box;
}

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  text-decoration: none;
}

a {
  &.current {
    color: $text-color;
    border-bottom: 1px solid $text-color;
  }
}
li, p {
  &.current {
    a {
      color: $text-color;
      border-bottom: 1px solid $text-color;
    }
  }
}

.product ul{
  color: rgb(117, 120, 123);
  font-size: 1.1rem;
  margin-top: 35px;
  list-style: disc;
  padding-left: 35px;
  font-weight: 500;

  li{
    padding-bottom: 4px;
  }
}
