/// Regular font family
/// @type List
$regular-font-stack: "Montserrat", "proxima-nova", Helvetica, Arial, sans-serif !default;

/// Code (monospace) font family
/// @type List
$alt-font-stack: "proxima nova", serif !default;

:root{
  --swiper-theme-color:rgb(76, 76, 76) !important;
}

/// Main brand color
/// @type Color
$brand-color: rgba(150, 201, 62, 1) !default;
$brand-full: rgba(150, 201, 62, 1) !default;
$brand-75: rgba(150, 201, 62, 0.75) !default;
$brand-50: rgba(150, 201, 62, 0.50) !default;
$brand-25: rgba(150, 201, 62, 0.25) !default;
$brand-10: rgba(150, 201, 62, 0.05) !default;
$brand-pale: rgba(150, 201, 62, 0.05) !default;

/// Light grey
/// @type Color
$light-grey: #E7EDEF !default;

$pale-grey: rgba(231, 238, 247, 0.3) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(119, 120, 118) !default;

/// Dark grey
/// @type Color
$dark-grey: rgba(21, 21, 21, 1) !default;

$dark-bg: #1E2424;

/// Highlight color
$highlight-color: rgb(210, 247, 236) !default;

/// Copy text color
/// @type Color
$text-color: rgba(21, 21, 21, 1);

$text-color-alt: rgb(145, 135, 131) !default;

$bg-color-alt: rgb(52, 46, 48) !default;

$white: rgb(255, 255, 255) !default;
$black: rgb(0, 0, 0) !default;


/// Standard padding

$pad: 1.7rem;


/// Container's maximum width
/// @type Length
$max-width: 2400px !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
  'mob': (max-width: 586px),
  'small': (max-width: 870px),
  'medium': (max-width: 1024px),
  'wide': (max-width: 1800px)
) !default;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

/// Grid variables

$col-count: 24 !default;
$gutter:    1rem !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
